<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i>
                    <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA INGRESO DE DOCUMENTOS STAGE 2</span>
                    <hr />
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="vr my-2 icon-custom">
                            <i class="fas fa-file-invoice fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaCheckList.length }}</span>
                            <br />
                            <span class="text-muted h6">CheckList</span>
                        </b-col>
                        <b-col cols="4" class="vr my-2 icon-custom">
                            <i class="fas fa-file-invoice fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaReports.length }}</span>
                            <br />
                            <span class="text-muted h6">Reports</span>
                        </b-col>
                        <b-col cols="4" class=" my-2 icon-custom">
                            <i class="fas fa-file-invoice fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaReporteExpertoTecnico.length }}</span>
                            <br />
                            <span class="text-muted h6">Reporte Experto Técnico</span>
                        </b-col>
                        <b-col cols="12">
                            <hr>
                        </b-col>
                        <b-col cols="4" class="vr my-2 icon-custom">
                            <i class="fas fa-file-invoice fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaClientFeedback.length }}</span>
                            <br />
                            <span class="text-muted h6">No Client Feedback</span>
                        </b-col>
                        <b-col cols="4" class="vr my-2 icon-custom">
                            <i class="fas fa-file-invoice fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaNonConformityReport.length }}</span>
                            <br />
                            <span class="text-muted h6">No Conformity Report</span>
                        </b-col>
                        <b-col cols="4" class=" my-2 icon-custom">
                            <i class="fas fa-file-alt fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaAuditTeamApproval.length }}</span>
                            <br />
                            <span class="text-muted h6">Audit Team Approval</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de ingreso de documentos stage 2</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión checkList stage 2' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo CheckList
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión report stage 2' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo Report
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión Tecnical Report stage 2' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo Reporte Experto Técnico
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión Cliente feedback stage 2' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo Client Feedback
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión Reporte nc stage 2' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo No Conformity Report
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión audit team approval stage 2' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo Audit Team Approval
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i>
                    <span class="h5"> Ingreso de Documentos Stage 2 </span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col md="12">
                                <b-tabs variant="pills">
                                    <b-tab active>
                                        <template slot="title">
                                            <i class="fas fa-file-invoice fa-md mr-1"></i> Check List
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[0]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[0]" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaCheckList" :fields="campoDocumentosIngreso" :current-page="currentPage[0]" :per-page="porPagina[0]" :filter="filter[0]" :filter-included-fields="filterOn[0]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,0)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoDocumentosIngreso">
                                                        <col v-for="field in campoDocumentosIngreso.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión checkList stage 2',params: { id: param.item.idCheckList },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarCheckList(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[0]" :total-rows="totalRows[0]" :per-page="porPagina[0]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-invoice fa-md mr-1"></i> Reports
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[1]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[1]" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaReports" :fields="campoDocumentosIngreso" :current-page="currentPage[1]" :per-page="porPagina[1]" :filter="filter[1]" :filter-included-fields="filterOn[1]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,1)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoDocumentosIngreso">
                                                        <col v-for="field in campoDocumentosIngreso.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión report stage 2',params: { id: param.item.idReport },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarReport(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[1]" :total-rows="totalRows[1]" :per-page="porPagina[1]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-invoice fa-md mr-1"></i> Reporte Experto Técnico
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[2]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[2]" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaReporteExpertoTecnico" :fields="campoDocumentosIngreso" :current-page="currentPage[2]" :per-page="porPagina[2]" :filter="filter[2]" :filter-included-fields="filterOn[2]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,2)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoDocumentosIngreso">
                                                        <col v-for="field in campoDocumentosIngreso.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión Tecnical Report stage 2',params: { id: param.item.idTecnicalReport },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarReporteExpertoTecnico(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[2]" :total-rows="totalRows[2]" :per-page="porPagina[2]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-invoice fa-md mr-1"></i> Client Feedback
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[3]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[3]" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaClientFeedback" :fields="campoDocumentosIngreso" :current-page="currentPage[3]" :per-page="porPagina[3]" :filter="filter[3]" :filter-included-fields="filterOn[3]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,3)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoDocumentosIngreso">
                                                        <col v-for="field in campoDocumentosIngreso.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión Cliente feedback stage 2',params: { id: param.item.idClienteFeedback },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarClientFeedback(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[3]" :total-rows="totalRows[3]" :per-page="porPagina[3]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-invoice fa-md mr-1"></i> Non Conformity Report
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[4]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[4]" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaNonConformityReport" :fields="campoDocumentosIngreso" :current-page="currentPage[4]" :per-page="porPagina[4]" :filter="filter[4]" :filter-included-fields="filterOn[4]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,4)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoDocumentosIngreso">
                                                        <col v-for="field in campoDocumentosIngreso.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión Reporte nc stage 2',params: { id: param.item.idReporteNC },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarNonConformityReport(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[4]" :total-rows="totalRows[4]" :per-page="porPagina[4]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-alt fa-md mr-1"></i> Audit Team Approval
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[5]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[5]" id="filterInput" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaAuditTeamApproval" :fields="campoDocumentosIngreso" :current-page="currentPage[5]" :per-page="porPagina[5]" :filter="filter[5]" :filter-included-fields="filterOn[5]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,5)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoDocumentosIngreso">
                                                        <col v-for="field in campoDocumentosIngreso.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión audit team approval stage 2',params: { id: param.item.idAuditTeamApproval },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarAuditTeamApproval(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[5]" :total-rows="totalRows[5]" :per-page="porPagina[5]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                </b-tabs>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            datosSession: {
                idCliente: '',
            },

            totalRows: [0, 0, 0, 0, 0, 0],
            currentPage: [1, 1, 1, 1, 1, 1],
            filter: [null, null, null, null, null, null],
            filterOn: [
                [],
                [],
                [],
                [],
                [],
                [],
            ],
            porPagina: [10, 10, 10, 10, 10, 10],
            filasTotales: [0, 0, 0, 0, 0, 0],
            paginaOpciones: [
                10,
                25,
                50,
                100,
                {
                    value: 9999999,
                    text: 'Mostrar todos',
                },
            ],

            campoDocumentosIngreso: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                // {
                //     key: 'razonSocial',
                //     label: 'Empresa',
                //     class: 'text-center',
                // },
                {
                    key: 'normas',
                    label: 'Normas',
                    class: 'text-center',
                },
                {
                    key: 'fechaCreacion',
                    label: 'Fecha de creación',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015',
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015',
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018',
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016',
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013',
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011',
                },
            ],
            listaCheckList: [],
            listaReports: [],
            listaReporteExpertoTecnico: [],
            listaClientFeedback: [],
            listaNonConformityReport: [],
            listaAuditTeamApproval: [],
        };
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems, item) {
            this.totalRows[item] = filteredItems.length;
            this.currentPage[item] = 1;
        },

        listarCheckList() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-check-list-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaCheckList = response.data;
                    me.filasTotales[0] = me.listaCheckList.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        listarReports() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-reports-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaReports = response.data;
                    me.filasTotales[1] = me.listaReports.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        listarReporteExpertoTecnico() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-reporte-experto-tecnico-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaReporteExpertoTecnico = response.data;
                    me.filasTotales[2] = me.listaReporteExpertoTecnico.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        listarClientFeedback() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-client-feedback-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaClientFeedback = response.data;
                    me.filasTotales[3] = me.listaClientFeedback.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        listarNonConformityReport() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-reporte-nc-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaNonConformityReport = response.data;
                    me.filasTotales[4] = me.listaNonConformityReport.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        listarAuditTeamApproval() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-audit-tema-approval-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaAuditTeamApproval = response.data;
                    me.filasTotales[5] = me.listaAuditTeamApproval.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },

        listarIngresoDocumentosS2() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/ingreso-documentos-s2', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaCheckList = response.data[0];
                    me.filasTotales[0] = me.listaReports.length;

                    me.listaReports = response.data[1];
                    me.filasTotales[1] = me.listaReports.length;

                    me.listaReporteExpertoTecnico = response.data[2]
                    me.filasTotales[2] = me.listaReporteExpertoTecnico.length;

                    me.listaClientFeedback = response.data[3]
                    me.filasTotales[3] = me.listaClientFeedback.length;

                    me.listaNonConformityReport = response.data[4]
                    me.filasTotales[4] = me.listaNonConformityReport.length;

                    me.listaAuditTeamApproval = response.data[5]
                    me.filasTotales[5] = me.listaAuditTeamApproval.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        eliminarCheckList(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el Checklist?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-check-list-s2', {
                                    idCheckList: param.item.idCheckList,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarCheckList();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!' + error);
                            });
                    }
                });
        },
        eliminarReport(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el report?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-pack-report-s2', {
                                    idReport: param.item.idReport,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarReports();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!');
                            });
                    }
                });
        },
        eliminarReporteExpertoTecnico(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el reporte experto técnico?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-reporte-experto-tecnico-s2', {
                                    idTecnicalReport: param.item.idTecnicalReport,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarReporteExpertoTecnico();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!');
                            });
                    }
                });
        },
        eliminarClientFeedback(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el client feedback?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-client-feedback-s2', {
                                    idClienteFeedback: param.item.idClienteFeedback,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarClientFeedback();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!');
                            });
                    }
                });
        },
        eliminarNonConformityReport(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar Non Conformity Report?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-reporte-nc-s2', {
                                    idReporteNC: param.item.idReporteNC,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarNonConformityReport();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!');
                            });
                    }
                });
        },
        eliminarAuditTeamApproval(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el audit team approval?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-pack-audit-team-approval-s2', {
                                    idAuditTeamApproval: param.item.idAuditTeamApproval,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarAuditTeamApproval();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!');
                            });
                    }
                });
        },

        swat(icon, title) {
            this.$swal
                .mixin({
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-right',
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    },
                })
                .fire({
                    icon: icon,
                    title: title,
                });
        },
    },

    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarIngresoDocumentosS2();
        }
    },
};
</script>
